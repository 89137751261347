<script lang="ts" setup>
import type { KeenSliderOptions } from "keen-slider";

const { t } = useT();
const { isSpecialBox } = useSpecialMagicBox();
const STATIC_DATA = [
	{
		icon: "benefits-promotions",
		class: "promotions",
		title: t("promotions"),
		keypath: "Explore our {key} and promotions.",
		key: "Generous bonuses"
	},
	{
		icon: "benefits-quest",
		class: "quest",
		title: t("tao quest"),
		keypath: "Compete in our quest and {key} Tao Coins!",
		key: "win 1,000,000"
	},
	{
		icon: isSpecialBox.value ? "benefits-magic-lanterns" : "benefits-magic-box",
		class: "magic-box",
		title: isSpecialBox.value ? t("Magic Lanterns") : t("magic box"),
		keypath: "{key} Tao Coins!",
		key: `Win up to ${numberFormat(Number(t("magicbox_prize_coins")))}`
	},
	{
		icon: "benefits-refer",
		class: "refer",
		title: t("refer a friend"),
		keypath: "Share a reward with your friend.",
		key: ""
	}
];

const animation = { duration: 20000, easing: (t: number) => t };

const sliderOptions: KeenSliderOptions = {
	loop: true,
	slides: { perView: "auto", spacing: 12 },
	drag: false,
	created(s) {
		s.moveToIdx(3, true, animation);
	},
	updated(s) {
		s.moveToIdx(s.track.details.abs + 3, true, animation);
	},
	animationEnded(s) {
		s.moveToIdx(s.track.details.abs + 3, true, animation);
	}
};
</script>

<template>
	<div class="benefits">
		<div class="benefits--desk d-none d-md-flex flex-wrap flex-xxl-nowrap gap-2">
			<div
				v-for="(item, i) in STATIC_DATA"
				:key="i"
				class="benefits__item d-flex flex-column align-items-stretch p-4"
				:class="item.class"
			>
				<div class="d-flex justify-content-between align-items-center">
					<AText type="h8" as="p" class="benefits__title m-0" variant="toledo">
						{{ item.title }}
					</AText>
					<NuxtImg
						class="benefits__icon"
						:src="`/nuxt-img/benefits/${item.icon}.png`"
						format="avif"
						loading="lazy"
						width="144"
						height="144"
						fit="contain"
						background="transparent"
						:alt="item.title"
					/>
				</div>

				<AText type="base">
					<i18n-t tag="p" class="benefits__text m-0" :keypath="item.keypath">
						<template #key>
							<b>{{ item.key }}</b>
						</template>
					</i18n-t>
				</AText>
			</div>
		</div>
		<div class="benefits--mob d-block d-md-none">
			<ASlider :options="sliderOptions">
				<div v-for="(item, i) in STATIC_DATA" :key="i" class="keen-slider__slide swiper-slide" :class="item.icon">
					<div class="benefits__item p-4" :class="item.class">
						<div class="d-flex justify-content-between align-items-center">
							<AText type="h8" as="p" class="benefits__title m-0" variant="toledo">
								{{ item.title }}
							</AText>
							<NuxtImg
								class="benefits__icon"
								:src="`/nuxt-img/benefits/${item.icon}.png`"
								format="avif"
								loading="lazy"
								width="72"
								height="72"
								fit="contain"
								background="transparent"
								:alt="item.title"
							/>
						</div>

						<AText type="base">
							<i18n-t tag="p" class="benefits__text m-0" :keypath="item.keypath">
								<template #key>
									<b>{{ item.key }}</b>
								</template>
							</i18n-t>
						</AText>
					</div>
				</div>
			</ASlider>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.benefits {
	&--mob {
		@include media-breakpoint-down(md) {
			width: calc(100% + 16px);
		}

		.swiper-slide {
			width: 100%;
		}
	}

	&__icon {
		width: 72px;
		height: 72px;
		object-fit: contain;
		object-position: 50% 50%;
	}

	&__item {
		height: 164px;
		border-radius: 12px;
		position: relative;
		width: 249px;

		@include media-breakpoint-up(md) {
			width: calc(50% - 4px);
		}

		@include media-breakpoint-up(xxl) {
			width: 25%;
		}

		&.promotions {
			background: var(--gradient-2);
		}

		&.quest {
			background: var(--gradient-4);
		}

		&.magic-box {
			background: var(--gradient-3);
		}

		&.refer {
			background: var(--gradient-6);
		}
	}
}
</style>
